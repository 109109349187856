<template>
  <div class=container>

        <table class=table  border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">Gallery of Separation Results</font></div></td>
          </tr>

          <tr>
            <td align="left"><h3></h3>
<blockquote>
  <p></p><div align="center"><router-link to="/gallery/separation/DGSB"><img src="https://cave.cs.columbia.edu/old/projects/separation/gallerythumb/diffspec.png" width="267" height="100" border="0"></router-link><br>
                              <span class="style1">.</span><br>
                              <router-link to="/gallery/separation/DGSB">Diffuse Groove and Specular Ball</router-link></div><br><p></p>
  
  <p></p><div align="center"><router-link to="/gallery/separation/RWO"><img src="https://cave.cs.columbia.edu/old/projects/separation/gallerythumb/realobjects.png" width="677" height="100" border="0"></router-link><br>
                              <span class="style1">.</span><br>
                           <router-link to="/gallery/separation/RWO">Real World Objects</router-link></div><br><p></p>
  
  <p></p><div align="center"><router-link to="/gallery/separation/PFF"><img src="https://cave.cs.columbia.edu/old/projects/separation/gallerythumb/foodplants.png" width="677" height="100" border="0"></router-link><br>
                              <span class="style1">.</span><br>
                           <router-link to="/gallery/separation/PFF">Plants, Flowers, Food</router-link></div><br><p></p>

  <p></p><div align="center"><router-link to="/gallery/separation/SH"><img src="https://cave.cs.columbia.edu/old/projects/separation/gallerythumb/skinhair.png" width="649" height="100" border="0"></router-link><br>
                              <span class="style1">.</span><br>
                           <router-link to="/gallery/separation/SH">Skin and Hair</router-link></div><br><p></p>

  <p></p><div align="center"><router-link to="/gallery/separation/PSS"><img src="https://cave.cs.columbia.edu/old/projects/separation/gallerythumb/pstereo.png" width="450" height="100" border="0"></router-link><br>
                              <span class="style1">.</span><br>
                           <router-link to="/gallery/separation/PSS">Photometric Stereo with Separation</router-link></div><br><p></p>

  <p></p><div align="center"><router-link to="/gallery/separation/SO"><img src="https://cave.cs.columbia.edu/old/projects/separation/gallerythumb/occluders.png" width="535" height="100" border="0"></router-link><br>
                              <span class="style1">.</span><br>
                           <router-link to="/gallery/separation/SO">Separation using Occluders</router-link></div><br><p></p>

  <p></p><div align="center"><router-link to="/gallery/separation/BTF"><img src="https://cave.cs.columbia.edu/old/projects/separation/gallerythumb/btf.png" width="268" height="100" border="0"></router-link><br>
                              <span class="style1">.</span><br>
                           <router-link to="/gallery/separation/BTF">Separation for Bidirectional Textures</router-link></div><br><p></p>

  <p></p><div align="center"><router-link to="/gallery/separation/SS"><img src="https://cave.cs.columbia.edu/old/projects/separation/gallerythumb/scale.png" width="535" height="100" border="0"></router-link><br>
                              <span class="style1">.</span><br>
                           <router-link to="/gallery/separation/SS">Separation at Different Scales of Observation</router-link></div><br><p></p>

  <p></p><div align="center"><router-link to="/gallery/separation/RF"><img src="https://cave.cs.columbia.edu/old/projects/separation/gallerythumb/realfake.png" width="416" height="100" border="0"></router-link><br>
                              <span class="style1">.</span><br>
                           <router-link to="/gallery/separation/RF">Distinguishing Between Real and Fake Objects</router-link></div><br><p></p>


</blockquote></td>
          </tr><tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
        
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    /* width:70%; */
    margin-left:auto;
    margin-right:auto;
	font-size: 18px;
}
.table{
        margin:0 auto;
}
</style>